@font-face {
  font-family: "Outfit";
  src: local("Outfit"),
  url("./assets/Outfit-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Docker One";
  src: local("Docker One"),
  url("./assets/Docker One.ttf") format("truetype");
  font-display: swap;
}

body, div#root {
  min-height: 100vh;
  scroll-behavior: smooth;
}

h1, h2, .docker-text {
  font-family: "Docker One";
}

h3, h4, h5, h6 {
  font-family: "Outfit";
  font-weight: bolder;
}

.secondary-font {
  font-family: "Outfit";
  font-weight: bolder;
}

.scrollbar-hidden {
  scrollbar-width: none;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.animated{
  -webkit-animation-duration:1s;
  animation-duration:1s;
  -webkit-animation-fill-mode:both;
  animation-fill-mode:both;
  opacity: 0;
}

.title-stroke {
  text-shadow: 1px 1px 0 #717171, -1px 1px 0 #717171, -1px -1px 0 #717171, 1px -1px 0 #717171;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn
}

@-webkit-keyframes fadeOut{0%{opacity:1}to{opacity:0}}@keyframes fadeOut{0%{opacity:1}to{opacity:0}}.fadeOut{-webkit-animation-name:fadeOut;animation-name:fadeOut}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 15%, 0);
    transform: translate3d(0, 15%, 0)
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 15%, 0);
    transform: translate3d(0, 15%, 0)
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 4px;
  width: 7.5px;
  height: 15px;
  border: solid black;
  border-width: 0 5px 5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.grecaptcha-badge {
  display: none!important;
}

.releases-grid-fill {
  grid-template-columns: repeat(auto-fill, minmax(156px, 1fr));
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .email-icon {
    background: url(./assets/social-icons/email.svg) no-repeat center !important;
  }
  .fb-icon {
    background: url(./assets/social-icons/facebook.svg) no-repeat center !important;
  }
  .ig-icon {
    background: url(./assets/social-icons/instagram.svg) no-repeat center !important;
  }
  .soundcloud-icon {
    background: url(./assets/social-icons/soundcloud.svg) no-repeat center !important;
  }
  .youtube-icon {
    background: url(./assets/social-icons/youtube.svg) no-repeat center !important;
  }
  .fb-black-icon {
    background: url(./assets/event-icons/fb-black.svg) no-repeat center !important;
  }
  .location-icon {
    background: url(./assets/event-icons/location.svg) no-repeat center !important;
  }
  .date-icon {
    background: url(./assets/event-icons/date.svg) no-repeat center !important;
  }
}


.footerImg {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
}

p {
  margin-block-start: 1em;
}

.wysiwygContent {
  h3 {
    font-size: 1.75rem;
  }
}